import React from 'react';
import Layout from '../../../../templates/layout';
import InvoiceDetails from '../../../../components/user/invoices/InvoiceDetails';

const InvoicesPage = props => (
  <Layout>
    <InvoiceDetails code={props.code} location={props.location} />
  </Layout>
);

export const Head = () => <title>Faktury | Zapłatomat</title>;

export default InvoicesPage;
